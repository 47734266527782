/* eslint-disable no-console */
// @flow
import { NoIndexMetaTag, PageContent, SEO } from "@components";
import { Constants } from "@utils";
import { determineStyle } from "@utils";
import { StorageContext } from "@utils/BrowserStorage/StorageContext";
import { LocaleContext } from "@utils/Localization/LocaleContext";
import { loadLocalStorageId } from "@utils/LocalStorage";
import withURLParameters from "@utils/ParametersHOC";
import { graphql } from "gatsby";
import React from "react";

import { generateCareerBreadcrumb } from "../utils/Breadcrumb";
import { career } from "../utils/ContentfulConstants/ContentfulTypenames";
import { careerJobDescription } from "../utils/Translations";

const findFirstPageSlug = (obj, currentPage) => {
    if (Array.isArray(obj)) {
        for (const item of obj) {
            const result = findFirstPageSlug(item, currentPage);
            if (result) return result;
        }
    } else if (obj && typeof obj === "object") {
        const pagelandingData =
            obj.pagelanding?.[0] || obj?.displaysection?.[0]?.pagelanding?.[0];
        if (pagelandingData) {
            const {
                slug = null,
                node_locale = null,
                title = null,
                breadcrumb = null,
            } = pagelandingData;
            return {
                slug,
                node_locale,
                title,
                breadcrumb,
                currentPage,
            };
        }

        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const result = findFirstPageSlug(obj[key], currentPage);
                if (result) return result;
            }
        }
    }
    return null;
};

const CareerTemplate = ({
    data: {
        page,
        job,
        channel,
        navigation,
        footer,
        market,
        markets: { nodes: markets },
        languages: { nodes: languages },
        site: {
            siteMetadata: { siteUrl },
        },
    },
    location,
}: *) => {
    let translatedCareer = {
        externalTitle: job.externalTitle,
        externalJobDescription: job.externalJobDescription,
        extJobDescFooter: job.extJobDescFooter,
        extJobDescHeader: job.extJobDescHeader,
        metaDescription: careerJobDescription(market.node_locale),
        path: job.path,
    };

    if (job.translations.length > 1) {
        const foundTranslation = job.translations.find(
            translation => translation.extLocale === market.node_locale,
        );

        // If a matching translation is found, use its properties
        if (foundTranslation) {
            translatedCareer = {
                externalTitle: foundTranslation.externalTitle,
                externalJobDescription: foundTranslation.externalJobDescription,
                extJobDescFooter: foundTranslation.extJobDescFooter,
                extJobDescHeader: foundTranslation.extJobDescHeader,
                path: foundTranslation.path,
                metaDescription: careerJobDescription(market.node_locale),
            };
        }
    }

    const translations = languages.map(({ code }) => ({
        node_locale: code,
        slug: `careers/${translatedCareer.path}`,
    }));

    const pageSlug = findFirstPageSlug(page.nodes, {
        slug: `careers/${translatedCareer.path}`,
        title: translatedCareer.externalTitle,
        node_locale: market.node_locale,
    });
    const navData = determineStyle("Default", navigation);
    const navLogo = navigation?.logo;
    return (
        <LocaleContext.Provider
            value={{
                language: navigation.node_locale,
                market,
            }}
        >
            <StorageContext.Provider
                value={loadLocalStorageId(Constants.QUERY_PARAMETERS)}
            >
                <SEO
                    title={translatedCareer.externalTitle}
                    siteUrl={siteUrl}
                    publishedMarkets={markets}
                    currentPath={location.pathname}
                    translations={translations}
                    metaDescription={translatedCareer.metaDescription}
                    page={{
                        __typename: career,
                        ...job,
                        ...translatedCareer,
                        language: market.node_locale,
                        navLogo,
                        pageSlug,
                    }}
                    market={market}
                />
                <NoIndexMetaTag
                    page={
                        pageSlug === null
                            ? { noIndex: true }
                            : { noIndex: false }
                    }
                    channel={channel}
                />
                <PageContent
                    navigation={navData}
                    footer={footer}
                    translations={translations}
                    language={market.node_locale}
                    breadcrumb={
                        pageSlug?.slug &&
                        generateCareerBreadcrumb(
                            pageSlug,
                            market,
                            page.breadcrumbBackgroundColor,
                        )
                    }
                    entry={{
                        __typename: career,
                        ...job,
                        ...translatedCareer,
                        language: market.node_locale,
                    }}
                    publishedMarkets={markets}
                    slug={`careers/${translatedCareer.path}`}
                    enableScrollTop
                    disablePip
                    hideCategoryBar
                    hideCategoryBarMobile
                />
            </StorageContext.Provider>
        </LocaleContext.Provider>
    );
};

export default withURLParameters(CareerTemplate);

export const query = graphql`
    query(
        $id: String = null
        $language: String!
        $marketCode: String!
        $channel: String!
        $categoryId: String = null
    ) {
        ...SiteMetadata
        page: allContentfulPlaceholderContainer(
            filter: { type: { eq: "Careers" }, node_locale: { eq: $language } }
        ) {
            nodes {
                pagelanding {
                    slug
                    node_locale
                    title
                    breadcrumb
                    breadcrumbBackgroundColor
                }
                displaysection {
                    pagelanding {
                        node_locale
                        slug
                        title
                        breadcrumb
                        breadcrumbBackgroundColor
                    }
                }
            }
        }
        job: jobRequisition(jobReqId: { eq: $id }) {
            path
            translations {
                externalTitle
                externalJobDescription
                extJobDescFooter
                extJobDescHeader
                extLocale
                path
            }
            country
            createdDateTime
            templateName
            extJobDescFooter
            externalJobDescription
            legalEntity
            externalTitle
            jobReqId
            extJobDescHeader
            locationList {
                address1
                address2
                city
                zipCode
            }
            jobCategory
        }
        channel: contentfulChannel(channel: { eq: $channel }) {
            noIndex
        }
        market: contentfulMarket(
            code: { eq: $marketCode }
            node_locale: { eq: $language }
        ) {
            ...MarketGeneral
        }
        markets: allContentfulMarket(
            filter: { node_locale: { eq: $language } }
        ) {
            nodes {
                ...MarketGeneral
            }
        }
        languages: allTpoContentfulLocale {
            nodes {
                code
            }
        }
        navigation: contentfulNavigation(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Navigation
        }
        footer: contentfulFooter(
            node_locale: { eq: $language }
            markets: { elemMatch: { code: { eq: $marketCode } } }
            channels: { elemMatch: { channel: { eq: $channel } } }
            default: { eq: true }
        ) {
            ...Footer
        }
    }
`;
